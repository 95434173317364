<template>
  <!-- 重置密码身份验证页 -->
  <div class="singin">
    <div class="navbar" @click="backClick">
      <van-icon name="down" color="rgba(0, 0, 0, 0.85)" size="24" />
    </div>
    <div class="title">
      <p>{{ $t("singin.title1") }}<br />{{ $t("singin.title2") }}</p>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group>
        <span class="field_label">{{ $t("singin.phone") }}</span>
        <van-field
          v-model="phone"
          name="phone"
          :placeholder="$t('singin.phone_warn')"
          :rules="[{ required: true, message: $t('singin.phone_warn') }]"
        />
      </van-cell-group>
      <van-cell-group>
        <span class="field_label">{{ $t("singin.name") }}</span>
        <van-field
          v-model="realName"
          name="realName"
          :placeholder="$t('singin.name_warn')"
          :rules="[{ required: true, message: $t('singin.name_warn') }]"
        />
      </van-cell-group>
      <van-cell-group>
        <span class="field_label">{{ $t("singin.idCard") }}</span>
        <van-field
          v-model="idCard"
          name="idCard"
          :placeholder="$t('singin.idCard_warn')"
          :rules="[{ required: true, message: $t('singin.idCard_warn') }]"
        />
      </van-cell-group>
      <div style="margin-top: 32px;">
        <van-button round block type="primary" native-type="submit">{{
          $t("singin.next")
        }}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { clerkAuth } from "@/network/login";
export default {
  name: "resetVerify",
  data() {
    return {
      realName: null,
      phone: null,
      idCard: null,
      type: "",
      typeUrl: "",
    };
  },
  created() {
    let data = JSON.parse(window.sessionStorage.getItem("resetInfo"));
    if (data) {
      this.phone = data.phone;
      this.realName = data.realName;
      this.idCard = data.idCard;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.type === "Y"
        ? (this.typeUrl = "/setting")
        : (this.typeUrl = "/login");
    }
  },
  methods: {
    // 存储填的信息
    setLoscl() {
      let singInfo = {
        phone: this.phone,
        realName: this.realName,
        idCard: this.idCard,
      };
      window.sessionStorage.setItem("resetInfo", JSON.stringify(singInfo));
    },
    backClick() {
      window.sessionStorage.removeItem('resetInfo')
      this.$router.push(this.typeUrl);
    },
    onSubmit(values) {
      let data = {
        name: values.realName,
        idCardNo: values.idCard,
        phone: values.phone,
        bankNo: "",
        type: "RESET_AUTH",
      };
      clerkAuth(data)
        .then((res) => {
          if(res.code === '10011'){
            return
          }
          if (res.code === "000000") {
            this.setLoscl();
            this.$router.push({
              path: "/forgetPassword",
              query: {
                data: values,
                type: this.type,
              },
            });
          } else {
            this.$dialog
              .alert({
                message: res.message,
                confirmButtonText:this.$t('stringCode.Done'),
              })
              .then(() => {
                // on close
              });
          }
        })
        .catch((err) => {
          this.$dialog
            .alert({
              message: res.message,
              confirmButtonText:this.$t('stringCode.Done'),
            })
            .then(() => {
              // on close
            });
        });
    },
  },
};
</script>

<style scoped>
.navbar {
  padding-top: 25px;
}
.singin /deep/ .van-icon {
  transform: rotate(90deg);
}
.singin {
  padding: 0 25px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
.title {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.singin /deep/ .van-field__control::placeholder {
  color: #ccc;
}
.singin /deep/ .van-cell-group {
  background-color: #fafafa;
  margin-bottom: 16px;
}
.singin /deep/ .van-hairline--top-bottom::after {
  border-width: 0 0 1px;
}
.singin /deep/ .van-cell {
  padding: 8px 0;
  background-color: #fafafa;
}
.field_label {
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.55);
}
</style>
